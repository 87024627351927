<template>
  <div class="maintenance-container">
    <img class="logo" :src="require('@/assets/PROTEST_WHITE_NOBG.png')" alt="Logo" />
    <h1 class="subtitle maintenance-subtitle"><b>Debunking Misconceptions & Creating Clarity 🧬</b></h1>

    <div class="social-links">
      <a href="https://www.instagram.com/protesthealth/" target="_blank">
        <i class="fa-brands fa-instagram fa-2xl"></i>
      </a>
      <a href="https://twitter.com/protesthealth" target="_blank">
        <i class="fa-brands fa-x-twitter fa-2xl"></i>
      </a>
    </div>
  </div>
</template>

<style>

  /* Existing styles from MaintenanceMode.vue */
  .maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
  }

  .maintenance-subtitle {
    margin-top: 20px;
    color: #ffffff !important;
  }

  .logo {
    width: 360px; /* Adjust the size of your logo */
    height: auto;
    margin-top: -100px;
  }

  .social-links {
    display: flex;
  }

  .social-links a {
    margin: 0 10px;
    width: 50px;
    height: auto;
    color: #f1e138;
    transition: color 0.1s; /* Add a smooth transition effect */
  }

  .social-links a:hover {
    color: #ffffff; /* Change the color on hover */
  }
</style>
