<template>
  <div id="wrapper">
    <nav v-if="!maintenanceMode" class="navbar is-clear">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><strong>PROTEST</strong></router-link>
      </div>
      <div class="navbar-menu">
        <div class="navbar-end">
          <template v-if="$store.state.isAuthenticated">
            <div class="navbar-item">
              <div class="buttons">
                <router-link to="/" class="button is-light"><i class="fa-solid fa-user"></i></router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <router-link to="/" class="navbar-item">Home</router-link>
            <router-link to="/about" class="navbar-item">About</router-link> 

            <div class="navbar-item">
              <div class="buttons">
                <router-link to="/" class="button is-dark"><strong>Subscribe</strong></router-link>
                <router-link to="/" class="button is-light"><strong>Sign in</strong></router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </nav>

    <section class="section">
      <MaintenanceMode v-if="maintenanceMode" />
      <div v-if="!maintenanceMode" class="columns is-fullheight">

        <div class="column is-main-content">
          <router-view  class="section" />
        </div>
      </div>
    </section>
    
    <footer v-if="!maintenanceMode" class="footer">
      <p class="has-text-centered"><i class="fa-brands fa-instagram"></i></p>
      <p class="has-text-centered">Copyright© 2023 Protest Health</p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import MaintenanceMode from '@/components/MaintenanceMode.vue';

export default {
  name: 'App',
  // beforeCreate() {
  //   this.$store.commit('initializeStore')

  //   const token = this.$store.state.token

  //   if (token) {
  //     axios.defaults.headers.common['Authorization'] = "Token" + token
  //   } else {
  //     axios.defaults.headers.common['Authorization'] = ''
  //   }
  // }

  data() {
    return {
      maintenanceMode: true, // Set this to true when you want to display maintenance mode
    };
  },
  components: {
    MaintenanceMode,
  },
}
</script>
<style>
  body, html {
    margin: 0;
    overflow: hidden;
    height: 100%;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #151515 !important;
  }
</style>